<template>
    <div class="index">
        <h1>网络文化经营许可证</h1>
        <img src="@/assets/img/网络文化经营许可证.png" width="800">
    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    setup() {

    }
})
</script>
<style lang='less' scoped>
.index {
    h1 {
        text-align: center;
    }
    text-align: center;
}
</style>