<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 14:35:57
 * @Description: file content
-->
<template>
  <el-container>
    <el-header height="60px">
      <div class="top">
        <div class="myLogo">
          <div>
            <img class="qs" src="./assets/img/logo_58.png" alt />
          </div>
          <div class="go-to-app" @click="go2App()">
            <div class="bg-img-change"></div>
            <div class="app-font">
              <span>App 下载</span>
            </div>
          </div>
          <div class="go-to-h5" @click="go2H5()">
            <img class="qs handle" src="./assets/img/cityapp-logo.png" alt />
          </div>
        </div>
        <div class="top-right">
          <el-tabs v-model="activeName" @tab-click="handleClick" style="padding-top: 10px">
            <el-tab-pane label="音乐列表" name="home"></el-tab-pane>
            <el-tab-pane label="音乐包" name="musicList"></el-tab-pane>
            <el-tab-pane label="纠纷处理" name="dispute"></el-tab-pane>
            <el-tab-pane label="自审制度" name="examine"></el-tab-pane>
            <el-tab-pane label="付费资讯" name="news"></el-tab-pane>
          </el-tabs>
          <div class="right-options">
            <div class="w-lg" v-if="!$store.state?.user?.userInfo.id">
              <div class="my-login" @click="login()">登录</div>
            </div>
            <el-dropdown v-else>
              <span class="el-dropdown-link">
                <el-avatar :src="$store.state?.user?.userInfo?.avatar"></el-avatar>
                <div class="name">{{ $store.state.user.userInfo.username }}</div>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="handleLogOut">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer>
      <div class="footer-main">
        <div class="cursor" @click="agreenebt('userAgreement')">用户权益保障管理制度</div>
        <div class="cursor" @click="agreenebt('technologyAgreement')">技术安全保障管理制度</div>
        <div>公司名：北京完美全未来信息技术有限公司</div>
        <br />
        <div>电话：010-578011341&nbsp;&nbsp;邮箱：yangmoyi@pwrd.com</div>
        <div>公司地址：北京市朝阳区望京东路1号4层401内A406</div>
        <div class="dis">
          <div class="cursor" @click="toICP()">京ICP备2021007091号-1</div>&nbsp;&nbsp;&nbsp;
          <div class="cursor" @click="toPolice()">京公网安备11010502043529号</div>&nbsp;&nbsp;&nbsp;
          <div class="cursor" @click="toPdf()">京网文[2021]1617-422号</div>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>
<script>
import { logout } from './service/UserService.js'
export default {
  data() {
    return {
      activeName: 'home',
      light: false,
    }
  },
  mounted() {
    this.activeName = this.$route.path.slice(1)
  },
  // watch: {
  //     $route: {
  //         deep: true,
  //         handler: function (v) {
  //             if (v?.path) {
  //                 this.activeName = v.path.slice(1);
  //             }
  //         }
  //     }
  // },
  methods: {
    go2H5() {
      // window.open(require('./assets/img/h5_detail.png'))
      this.$router.push('/cityapp')
    },
    go2App() {
      window.open('/s/d')
    },
    login() {
      this.$router.push('/login')
    },
    agreenebt(val) {
      this.activeName = ''
      this.$router.push(`/${val}`)
    },
    handleClick(val) {
      const item = { ...val }
      this.$router.push(`/${item.paneName}`)
      this.$store.commit('changeAudio', {
        show: false,
        songName: '',
        bgImg: '',
      })
      this.$store.state.audioRef.pause()
    },
    // 退出登录
    handleLogOut() {
      logout().then(() => {
        window.location.href = '/'
        this.$store.commit('clearUser')
      })
    },
    toICP() {
      window.open('http://beian.miit.gov.cn/')
    },
    toPolice() {
      window.open(
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502043529'
      )
    },
    toPdf() {
      this.$router.push({
        path: '/license'
      })
    }
  },
}
</script>
<style lang="less" scoped>
.dis {
  display: flex;
  justify-content: center;
}
.handle {
  cursor: pointer;
}
.go-to-h5 {
  padding-top: 5px;
}
.qs {
  width: 50px;
}
.go-to-app {
  width: 100px;
  padding: 15px 25px;
  display: flex;
  cursor: pointer;

  .app-font {
    color: #adadad;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .bg-img-change {
    height: 35px;
    width: 35px;
    background-image: url("./assets/img/app_h.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &:hover {
    .bg-img-change {
      background-image: url("./assets/img/app_y.png");
    }
    .app-font {
      color: #ffe12d;
    }
  }
}

.myLogo {
  display: flex;
}
.w-lg {
  padding-top: 5px;
}
.my-login {
  cursor: pointer;
  border: 1px #888b8c solid;
  padding: 5px;
  color: #888b8c;
  border-radius: 6px;
  width: 36px;
  text-align: center;
}
.top-right {
  display: flex;
}
.right-options {
  margin-left: 50px;
  padding-top: 10px;
  .name {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 10px;
    color: #5d5757;
  }

  .el-dropdown-link {
    display: flex;
  }
}
.user {
  display: flex;
  .userName {
    color: gray;
  }
}
.cursor:hover {
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}
.el-main {
  min-height: 720px;
  padding: 0 0px !important;
}
.el-footer {
  padding: 0 0px !important;
}
.footer-main {
  padding-top: 21px;
  text-align: center;
  flex-direction: column;
  display: flex;
  color: white;
  font-size: 12px;
  background-color: #353334;
  height: 175px;
  padding: 15px 0px;
}
.top {
  font-size: 13px !important  ;
  display: flex;
  justify-content: space-between;
  // padding-top: 20px;

  .el-tabs__item {
    font-size: 12px;
  }
}
</style>