<!--
 * @Author: liupengwu
 * @Date: 2021-02-20 14:58:34
 * @Description: file content
-->
<template>
  <div class="technology">
    <h1 class="title">技术安全保障管理制度</h1>
    <p class="usually light">一、网站安全保障措施</p>
    <p class="usually">1、网站服务器和其他计算机之间设置经公安部门认证的防火墙，并与专业网络安全公司合作，做好安全策略，拒绝外来的恶意攻击，保障网站正常运行。</p>
    <p class="usually">2、在网站的服务器及工作站上均安装了正版的防病毒软件， 对计算机病毒、有害电子邮件有整套的防范措施，防止有害信息对网站系统的干扰和破坏。</p>
    <p
      class="usually"
    >3、做好生产日志的留存。网站具有保护 60天以上的系统运行日志和用户使用日志记录功能，内容包括 IP 地址及使用情况，主页维护者、邮箱使用者和对应的 IP 地址情况等。</p>
    <p class="usually">4、交互式栏目具备有 IP 地址、身份登记和识别确认功能， 对没有合法手续和不具备条件的电子公告服务立即关闭。</p>
    <p class="usually">5、网站信息服务系统建立双机热备份机制，一旦主系统遇到故障或受到攻击导致不能正常运行，保证备用系统能及时替换主系统提供服务。</p>
    <p class="usually">6、关闭网站系统中暂不使用的服务功能，及相关端口，并及时用不定修复系统漏洞，定期查杀病毒。</p>
    <p class="usually">7、服务器平时处于锁定状态，并保管好登录密码；后台管理界面设置超级用户名及密码，并绑定IP，以防他人登入。</p>
    <p
      class="usually"
    >8、网站提供集中式权限管理，针对不同的应用系统、终端、操作人员，由网站系统管理员设置共享数据库信息的访问权限，并设置相应密码及口令。不同的操作人员设定不同的用户名，且定期更换，严禁操作人员泄露自己的口令。对操作人员的权限严格按照岗位职责设定，并由网站系统管理员定期检查操作人员权限。</p>
    <p class="usually">9、公司机房按照电信机房标准建设，内有必备的独立UPS不间断电源、高灵敏度的烟雾探测系统和消防系统，定期进行电力、防火、防潮、防磁和防鼠检查。</p>
    <p class="usually">10、在网站运行维护人员任用前、任用中、任用终止过程中进行相应的筛选、教育，纪律规范，规避过程中的安全风险。</p>
    <p class="usually light">二、用户信息安全及保密管理制度</p>
    <p class="usually">1、遵守《中华人民共和国计算机信息系统安全保护条例》及其他有关的国家法律、法规和规定。</p>
    <p class="usually">2、制定和严格执行安全管理制度。</p>
    <p class="usually">3、保存会员登录记录60天以上。</p>
    <p class="usually">4、会员登记的私人信息资料，网站不得向外界散发、告知。未经许可，任何单位、团体、个人不得查阅、拷贝、抄录私人信息资料内容。</p>
    <p class="usually">5、会员不得传播违反国家法律的言论，对传播淫秽资料的，管理员和版主有责任阻止。</p>
    <p class="usually">6、切实落实各项制度，做好各项管理制度的工作。</p>
    <p class="usually">7、信息安全内部人员保密管理制度：</p>
    <p class="usually">（1）相关内部人员不得对外泄露需要保密的信息；</p>
    <p class="usually">（2）内部人员不得发布、传播国家法律禁止的内容；</p>
    <p class="usually">（3） 信息发布之前应该经过相关人员审核；</p>
    <p class="usually">（4）对相关管理人员设定网站管理权限，不得越权管理网站信息；</p>
    <p class="usually">（5）一旦发生网站信息安全事故，应立即报告相关方并及时进行协调处理；</p>
    <p class="usually">（6）对有毒有害的信息进行过滤、用户信息进行保密设置</p>
    <p class="usually">8、 登陆用户信息安全管理制度：</p>
    <p class="usually">（1）对登陆用户信息阅读与发布按需要设置权限，不得擅自进入系统，篡改他人信息 ；</p>
    <p class="usually">（2）对有害的信息进行过滤，对用户的信息进行有效管理并保证其信息的安全保密 ；</p>
    <p class="usually">（3）建立信息编辑、审核、发布责任制及流程，信息发布之前必须经过相关人员审核 ；</p>
    <p class="usually">（4） 对用户在网站上的行为进行有效监控，保证内部信息安全；</p>
    <p class="usually">（5）固定用户不得传播、发布国家法律禁止的内容；</p>
    <p class="usually">（6）一旦发生信息安全事故，就积极采取有效措施，并保存记录按规定报告有关部门。</p>
    <p
      class="usually"
    >9、为防止病毒造成严重后果，对外来光盘、软件要严格管理，原则上不允许外来光盘、软件在本所局域网计算机上使用。确因工作需要使用的，事先必须进行防（杀）毒处理，证实无病毒感染后，方可使用。</p>
    <p class="usually">10、接入网络的计算机严禁将计算机设定为网络共享，严禁将机内文件设定为网络共享文件。</p>
    <p class="usually">11、为防止黑客攻击和网络病毒的侵袭，接入网络的计算机一律安装杀毒软件，并要定时对杀毒软件进行升级。</p>
    <p class="usually">12、严禁将材料存放在网络硬盘上。</p>
    <p class="usually">13、严禁将涉密办公计算机擅自连接国际互联网。</p>
    <p class="usually">14、保密级别在秘密一下的材料可通过电子信箱传递和报送， 严禁保密级别在秘密以上的材料通过电子信箱、QQ等网上传递和报送。</p>
    <p class="usually">15、涉密计算机严禁直接或间接连接国际互联网和其他公共信息网络，必须实行物理隔离。</p>
    <p class="usually">16、要坚持“谁上网，谁负责”的原则，各部门要有一名领导负责此项工作，信息上网必须经过所领导严格审查，并经主管领导批准。</p>
    <p class="usually">17、国际互联网必须与涉密计算机系统实行物理隔离。</p>
    <p class="usually">18、在与国际互联网相连的信息设备上不得储存、处理、和传输任何涉密信息。</p>
    <p class="usually">19、应加强对上网人员的保密意识教育，提高上网人员保密观念，增强防范意识，自觉执行保密规定。</p>
    <p
      class="usually"
    >20、涉密人员在其它场所上国际互联网是，要提高保密意识， 不得在聊天室、电子公告系统、网络新闻上发布、谈论和传播国家秘密信息。使用电子函件进行网上信息交流，应当遵守国家保密规定，不得利用电子函件传递、转发或抄送国家秘密信息。</p>
    <p
      class="usually"
    >21、为确保信息安全工作有明确的方向和获得可见的管理者支持，设立以下不同级别的信息安全管理机构负责网络安全、信息安全、数据安全，进行统筹：CEO、信息安全委员会、信息安全部、内审风控部、法务部、公共安全中心、人力资源中心、开发运维中心、业务部门。</p>
  </div>
</template>
<script>
export default {
  name: 'technologyAgreement',
}
</script>
<style lang="less"  scoped >
.technology {
  padding: 0 20%;
  padding-bottom: 30px;
  .usually {
    line-height: 24px;
    font-size: 14px;
    /* margin-bottom: 16px; */
    text-indent: 28px;
    margin: 8px 0px !important;
  }
  .title {
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 32px;
    text-align: center;
  }
  .light {
    font-weight: bold;
  }
}
</style>