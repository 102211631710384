/*
 * @Author: liupengwu
 * @Date: 2021-02-22 17:16:30
 * @Description: file content
 */
import { getJson } from '../utils/futureAjaxUtil.js';


//查询轮播音频列表
export function getIndexFour() {
  return getJson(`/api/future/mall/portal/official/v1/n/carousel`);
}

//发送所有的音频
export function getAllSong() {
  return getJson(`/api/future/mall/portal/official/v1/n/audiolist`);
}

//获取二维码
export function getQRCode(audioId) {
  return getJson(`/api/future/mall/portal/official/v1/n/audio/order/${audioId}`);
}
//刷新支付状态
export function getPlayStatus(orderId) {
  return getJson(`/api/future/mall/portal/official/v1/n/audio/paidstate/${orderId}`);
}


