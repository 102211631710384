/*
 * @Author: liupengwu
 * @Date: 2021-02-05 12:46:42
 * @Description: file content
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

router.beforeEach((to,from,next) => {
    document.title = to.meta?.title || '全未来'
    next()
})

createApp(App).use(store).use(router).use(ElementPlus).mount('#app');
