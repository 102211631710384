<!--
 * @Author: liupengwu
 * @Date: 2021-02-20 14:58:11
 * @Description: file content
-->
<template>
  <div class="userAgreement">
    <h1 class="title">用户权益保障管理制度</h1>
    <p class="usually">根据文化部《关于网络音乐发展和管理的若干意见》，制定如下用户协议。</p>
    <p class="usually">北京完美全未来信息技术有限公司 用户协议</p>
    <p class="usually">2021年1月1日</p>
    <p class="usually light">1. 特别提醒</p>
    <p class="usually">
      1.1 欢迎使用北京完美全未来信息技术有限公司（以下称“北京完美全未来”）为您（以下称“用户”）提供的服务。请用户仔细阅读以下全部内容，
      <span
        class="weight-line"
      >特别是粗体下划线标注的内容。</span>
    </p>

    <p class="usually weight-line">
      1.2
      若用户是18周岁以下的未成年人或存在其他不具备与用户行为相适应的民事行为能力的情形，在使用北京完美全未来提供的服务前，请用户务必在父母或监护人陪同下阅读本协议并取得父母或监护人的同意，并在父母或监护人在场的情况下使用本协议所述的服务。在此情况下，用户与用户的父母或监护人应按照法律规定承担因此而产生的一切后果。
    </p>
    <p
      class="usually"
    >1.3 如用户对本协议的内容表示异议，或者无法准确理解某项条款，请用户不要注册、登录或使用北京完美全未来提供服务；用户在北京完美全未来所提供的服务的登录、查看、下载、发布信息内容、使用等行为将被视为用户已阅读并同意受本协议及相关协议、规则的约束。</p>
    <p class="usually">
      本协议如由于北京完美全未来发展需要进行修订的，北京完美全未来将公开发布，以供用户查阅最新版协议条款。
      <span
        class="weight-line"
      >在北京完美全未来修改上述条款后，如果用户不接受修改后的条款，可以选择终止使用北京完美全未来提供的服务；继续使用的，将被视为已接受了修改后的条款。</span>
    </p>
    <p class="usually light">2. 账号管理规则</p>
    <p
      class="usually"
    >2.1 用户可以通过北京完美全未来提供的服务内容。用户的帐号是北京完美全未来确认用户身份的唯一依据，请用户在注册帐号后自行妥善保管帐号及密码。用户将自行承担通过该帐号进行的任何信息内容发布、数据修改、款项支付等行为所可能引起的法律责任。若用户未保管好自己的帐号和密码而给用户、北京完美全未来或第三方造成损害的，由用户依照法律规定承担责任。</p>
    <p
      class="usually"
    >2.2 用户应通过北京完美全未来正规渠道进行帐号注册，并在注册时按照注册提示合法、准确、真实地填写相关注册信息。昵称、头像和简介等账号信息资料应遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性，不得在账号信息资料中出现违法信息或不良信息，不得冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式开设帐号。用户保证在填写、登录、使用账号信息资料时，不得有以下情形：</p>
    <p class="usually">（1）反对宪法所确定的基本原则的；</p>
    <p class="usually">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p class="usually">（3）损害国家荣誉和利益的；</p>
    <p class="usually">（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或其它方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
    <p class="usually">（5）宣扬恐怖主义、极端主义或煽动实施恐怖活动、极端主义活动的；</p>
    <p class="usually">（6）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p class="usually">（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p class="usually">（8）散布谣言，扰乱经济秩序和社会秩序的；</p>
    <p class="usually">（9）散步淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；</p>
    <p class="usually">（9）散步淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；</p>
    <p class="usually">（10）侮辱或诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
    <p class="usually">（11）含有法律、行政法规所禁止的其它内容的。</p>
    <p
      class="usually"
    >2.3 用户不得不正当注册帐号，包括但不限于频繁注册、批量注册帐号、使用他人身份注册、利用或破坏帐号注册系统进行帐号注册等行为。对于通过不正当注册获得的帐号，北京完美全未来有权采取包括但不限于拒绝提供相关服务、封禁、注销或回收等措施，如因此发生任何损失的，用户将自行承担由此产生的全部责任。</p>
    <p
      class="usually"
    >2.4 用户不得赠与、借用、租用、转让、售卖帐号，或者以其他方式许可、共享他人使用帐号。若北京完美全未来发现或者有合理理由认为使用者并非帐号初始注册人，为保障帐号安全，北京完美全未来有权采取包括但不限于立即暂停或终止向该帐号提供服务、封禁、注销或回收等措施，北京完美全未来保留向其追究违约责任和其他法律责任的权利。</p>
    <p
      class="usually"
    >2.5 用户有责任妥善保管帐号信息及帐号密码的安全，在任何情况下不向他人透露帐号及密码信息、验证信息。当在用户发现自己帐号非自己使用时，请立即通知北京完美全未来。</p>
    <p
      class="usually"
    >2.6 如用户丢失帐号或遗忘密码，可通知北京完美全未来找回帐号或密码。北京完美全未来将根据申请时用户提供的相关资料信息与系统记录进行比对，但北京完美全未来无法准确识别申请时用户是否是该帐号的真正有权使用者。因用户自身原因或其他不可抗因素导致帐号遭受盗号或密码丢失，北京完美全未来不承担相应责任。</p>
    <p
      class="usually weight-line"
    >2.7 在法律允许范围内，用户同意北京完美全未来对用户帐号享有包括但不限于用户注册、使用该帐号产生的注册、登录、消费服务数据和使用统计记录的所有权和使用权。发生争议时，用户同意以北京完美全未来的系统数据为准，北京完美全未来应保证前述数据的真实性，并保证按照法律规定对数据的收集、存储采取相应的加密、匿名化处理等安全措施。</p>
    <p
      class="usually weight-line"
    >2.8 为符合相关法律法规的要求，保障用户的帐号安全，北京完美全未来可能会对用户的身份进行验证，包括但不限于要求输入验证码、手机号认证等，如用户无法完成验证或拒绝验证的，北京完美全未来可合理怀疑用户的帐号存在安全风险，暂停向用户提供部分或全部服务，并有权采取进一步措施。用户如果长期不登录帐号，或在多台终端设备上同时使用、显示、运行同一帐号等异常登录，或涉嫌违反北京完美全未来本协议及单独协议的有关规定，北京完美全未来有权立即暂停或终止向该帐号提供服务，并有权永久禁用该帐号。</p>
    <p
      class="usually"
    >2.9 北京完美全未来为用户提供帐号注销，用户可通过在线申请或联系客服的方式注销帐号，但法律法规另有规定或本协议另有约定的除外。一旦注销帐号，将无法再以此帐号登录和使用北京完美全未来提供服务。帐号注销后，帐号内的所有信息内容（包括但不限于用户所购买的服务和内容、上传的所有内容等）将无法恢复。</p>
    <p class="usually light">3. 用户行为规范</p>
    <p class="usually">3.1 用户在使用北京完美全未来的服务时不得从事以下行为：</p>
    <p class="usually">（1）反对宪法所确定的基本原则的；</p>
    <p class="usually">（2）危害国家安全统一，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p class="usually">（3）损害国家荣誉和利益的；</p>
    <p class="usually">（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
    <p class="usually">（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
    <p class="usually">（6）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p class="usually">（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p class="usually">（8）散布谣言，扰乱经济秩序和社会秩序的；</p>
    <p class="usually">（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p class="usually">（10）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
    <p class="usually">（11）法律、行政法规禁止的其他内容。</p>
    <p class="usually">3.1.2 发布、传送、传播、存储下列不良信息：</p>
    <p class="usually">（1）使用夸张标题，内容与标题严重不符的；</p>
    <p class="usually">（2）炒作绯闻、丑闻、劣迹等的；</p>
    <p class="usually">（3）不当评述自然灾害、重大事故等灾难的；</p>
    <p class="usually">（4）带有性暗示、性挑逗等易使人产生性联想的；</p>
    <p class="usually">（5）展现血腥、惊悚、残忍等致人身心不适的；</p>
    <p class="usually">（6）煽动人群歧视、地域歧视等的；</p>
    <p class="usually">（7）宣扬低俗、庸俗、媚俗内容的；</p>
    <p class="usually">（8）可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；</p>
    <p class="usually">（9）其他对网络生态造成不良影响的内容。</p>
    <p class="usually">3.1.3 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>
    <p class="usually">3.1.4 使用任何包含有通过侵犯知识产权、商业机密或任何一方的其他合法权利的方式利用本服务获得的相应的音频、视频资料；</p>
    <p class="usually">3.1.5 制作、发布、传播用于窃取他人帐号及他人专属信息、财产、保密信息的软件；</p>
    <p
      class="usually"
    >3.1.6 在未经北京完美全未来书面明确授权前提下，出售、出租、出借、散布、转移或转授权北京完美全未来提供的服务或相关的链接或从使用服务或服务条款中获利，无论以上使用是否为直接经济或金钱收益；</p>
    <p class="usually">3.1.7 未经北京完美全未来允许，擅自将音乐内容资源库中的任何音乐资源应用于商业用途或进行营利活动；</p>
    <p class="usually">3.1.8 虚构事实、隐瞒真相以误导、欺骗他人；</p>
    <p class="usually">3.1.9 发表、传送、传播商业广告、或类似的商业招揽信息、过度营销信息及垃圾信息；</p>
    <p class="usually">3.1.10 以其他任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和本服务；</p>
    <p class="usually">3.1.11 从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
    <p
      class="usually"
    >3.2 用户在使用北京完美全未来的服务时，应严格遵循上述约定，如有违反应自行承担使用信息内容可能产生的一切后果与责任，北京完美全未来有权采取包括但不限于拒绝提供部分或全部服务，删除违法或不良信息、对帐号进行封禁等措施，同时有权按照法律规定保存记录并向有关主管部门报告。因用户违反上述约定导致北京完美全未来遭受行政处罚、诉讼赔偿时，北京完美全未来有权要求用户共同解决，并向该用户追偿。</p>
    <p class="usually light">4. 未成年人使用条款</p>
    <p class="usually">4.1 未成年人用户在使用北京完美全未来所提供的服务时应注意以下事项，提高安全意识，加强自我保护：</p>
    <p class="usually">（1）认清网络世界与现实世界的区别，在合理范围内正确学习使用网络，避免沉迷于网络，影响日常的学习生活；</p>
    <p class="usually">（2）填写个人信息时，加强个人保护意识，并在取得监护人的同意以及在监护人的指导下进行填写提供，以免不良分子对个人造成骚扰和伤害；</p>
    <p class="usually">（3）在父母、监护人或老师的指导下，学习正确使用网络，养成良好上网习惯；</p>
    <p class="usually">（4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。</p>
    <p
      class="usually"
    >4.2 为更好地保护未成年人隐私权益，用户在发布包含未成年人素材的内容前应审慎考虑，一经发布，即视为用户已获得相关权利人同意在北京完美全未来的服务中展示未成年人的肖像、声音、姓名、年龄等信息，且同意北京完美全未来依据本协议、使用、处理与未成年人相关的内容。</p>
    <p
      class="usually"
    >4.3 被监护人（即未成年人）使用北京完美全未来所提供的服务的，您作为监护人应指导并监督被监护人的注册和使用行为，如被监护人注册、登录帐号，北京完美全未来有权认为其已取得您的同意。</p>
    <p class="usually light">5. 不可抗力及其他免责事由</p>
    <p
      class="usually"
    >5.1 用户理解并同意，在使用北京完美全未来所提供的服务的过程中，可能会遇到不可抗力等风险因素，使服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，北京完美全未来将努力在第一时间与相关单位配合，及时进行修复，但是北京完美全未来对由此给用户造成的损失在法律允许的范围内免责。</p>
    <p class="usually">5.2 在法律允许的范围内，北京完美全未来对以下情形导致的服务中断或受阻不承担责任：</p>
    <p class="usually">（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</p>
    <p class="usually">（2）用户或北京完美全未来的电脑软件、系统、硬件和通信线路出现故障；</p>
    <p class="usually">（3）用户操作不当；</p>
    <p class="usually">（4）用户通过非北京完美全未来授权的方式使用服务。</p>
    <p
      class="usually"
    >5.3 用户理解并同意，在使用北京完美全未来所提供的服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，北京完美全未来不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给用户造成的损害负责。这些风险包括但不限于：</p>
    <p class="usually">（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；</p>
    <p class="usually">（2）因使用本服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；</p>
    <p class="usually">（3）其他因网络信息或用户行为引起的风险。</p>
    <p
      class="usually weight-line"
    >5.4 用户理解并同意，北京完美全未来对于北京完美全未来提供的服务的商业适用性、特定用途适用性等不提供任何明示或暗示担保或条件，用户应自行对其使用服务的行为承担相应的风险；用户在使用服务时发布的任何内容、信息等并不反映或代表北京完美全未来的观点、立场或政策，用户应对其他用户发布的内容自行加以判断，并自行承担该内容给自己、他人及社会造成的一切法律责任，包括但不限于因对内容的准确性、真实性、完整性或实用性等的依赖而产生的风险。</p>
    <p
      class="usually weight-line"
    >5.5 任何情况下，北京完美全未来均不对任何间接性、后果性、惩罚性、偶然性、特殊性或惩罚性的损害承担责任。除法律法规另有明确规定外，北京完美全未来对用户承担的全部责任不应超过用户因使用北京完美全未来所提供的相应服务而向北京完美全未来支付的费用（如有）。</p>
    <p class="usually light">6. 知识产权</p>
    <p
      class="usually"
    >6.1 北京完美全未来在服务中提供的包括但不限于软件、技术、程序、音频、视频、歌曲列表、版面设计、电子文档、硬件产品等著作权、商标权、专利权、商业秘密等知识产权属于北京完美全未来所有或者已获得合法的授权。</p>
    <p
      class="usually"
    >6.2 未经北京完美全未来或相关权利人书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方对任何商标、商号、域名或其他显著品牌特征等实施展示、使用、转让或申请注册商标、域名注册等行为。</p>
    <p
      class="usually"
    >6.3 用户在使用北京完美全未来服务时发布的信息内容均由用户原创或已获合法授权（且含转授权）。用户通过北京完美全未来发布的信息内容的知识产权归属用户或原始权利人所有，且用户应对发布的信息内容承担责任。</p>
    <p class="usually light">7. 违约责任</p>
    <p
      class="usually"
    >7.1 如果北京完美全未来发现或收到他人举报或投诉用户违反本协议约定、或者存在侵权/违法行为的，北京完美全未来有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁的处罚。</p>
    <p
      class="usually"
    >7.2 北京完美全未来依据上述约定获得处理违法违规内容的权利，该权利不构成北京完美全未来的义务或承诺，北京完美全未来不能保证及时发现违法行为或进行相应处理。</p>
    <p
      class="usually"
    >7.3 北京完美全未来有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</p>
    <p
      class="usually weight-line"
    >7.4 用户理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；北京完美全未来因此遭受损失的，用户也应当一并赔偿。</p>
    <p class="usually light">8. 法律</p>
    <p
      class="usually weight-line"
    >8.1 本协议适用中华人民共和国的法律，并且排除一切冲突法规定的使用。如用户在使用本协议项下服务中出现纠纷的，用户可以在北京完美全未来信息技术有限公司所在地人民法院提起诉讼。</p>
    <p class="usually light">9. 其他</p>
    <p class="usually">9.1 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
  </div>
</template>
<script>
export default {
  name: 'userAgreement',
}
</script>
<style lang="less"  scoped >
.userAgreement {
  padding: 0 20%;
  padding-bottom: 30px;
  .usually {
    line-height: 24px;
    font-size: 14px;
    /* margin-bottom: 16px; */
    text-indent: 28px;
    margin: 8px 0px !important;
  }
  .title {
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 32px;
    text-align: center;
  }
  .light {
    font-weight: bold;
  }
  .weight-line {
    font-weight: bold;
    text-decoration: underline;
  }
}
</style>