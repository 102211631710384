<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 14:24:01
 * @Description: file content
-->
  <template >
    <div>
        <div class="header">全部音乐</div>
        <div class="main">
            <div
                class="item"
                v-for="song in $store.state.allSongList"
                :key="song.id"
            >
                <div class="light">
                    <img
                        class="myImg"
                        @click="palySong(song)"
                        :src="song.imgUrl"
                        alt=""
                    />
                    <div class="content">
                        <div class="option">
                            <div class="name" @click="palySong(song)">
                                {{ song.name }}
                            </div>
                            <div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="palySong(song)"
                                    >在线试听</el-button
                                >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    v-if="!song.paid"
                                    @click="buy(song)"
                                    >购买音乐</el-button
                                >
                                <el-button
                                    type="primary"
                                    size="mini"
                                    v-if="song.paid"
                                    @click="download(song)"
                                    >下载音乐</el-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-dialog
        title=""
        v-model="dialogVisible"
        width="35%"
    >
        <div>
            <div class="dialog-header">
                <div class="chat-play-img">
                    <img
                        :src="require('../assets/img/wechat-play.png')"
                        alt=""
                    />
                </div>
                <div style="width: 104px">
                    <div style="font-size: 18px">微信支付</div>
                    <div style="font-size: 12px">WeChatPlay</div>
                </div>
            </div>
            <div class="dialog-content">
                <div class="small">
                    <img :src="'data:image/bmp;base64,' + qrCodeImg" alt="" />
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { getQRCode, getPlayStatus, getAllSong } from '../service/FutureService.js';
import { ElMessage } from 'element-plus';
export default {
    name: 'musicList',
    data() {
        return {
            dialogVisible: false,
            qrCodeImg: '',
            orderId: '',
            timer: null
        };
    },
    watch: {
        dialogVisible(val) {
            if (val) {
                this.interval();
            } else {
                clearInterval(this.timer);
            }
        }
    },
    created() {
        //保证存在全部列表
        if (this.$store.state.allSongList.length === 0) {
            getAllSong().then((resp) => {
                if (resp.code === 200) {
                    this.$store.commit('setAllSongList', resp.data || []);
                }
            });
        }
    },
    methods: {
        //下载
        download(item) {
            window.open('https:' + item.audioUrl);
        },
        //刷新支付状态定时去
        interval() {
            const orderId = this.orderId;
            this.timer = setInterval(() => {
                getPlayStatus(orderId).then((resp) => {
                    if (resp.data.paid) {
                        ElMessage.success({
                            message: '支付成功',
                            type: 'success'
                        });
                        this.$store.commit('changeTwoArrByItem', resp.data);
                        this.dialogVisible = false;
                    }
                });
            }, 1000);
        },
        //购买
        buy(item) {
            if (this.$store.state.user.userInfo?.id) {
                getQRCode(item.id).then((resp) => {
                    this.qrCodeImg = resp.data.base64;
                    this.orderId = resp.data.orderItemNo;
                    this.dialogVisible = true;
                });
            } else {
                ElMessage.warning({
                    message: '请先登录之后再购买',
                    type: 'warning'
                });
            }

        },
        //播放
        palySong(item) {
            this.$store.commit('changeAudio', {
                show: true,
                songName: item.name,
                bgImg: item.imgUrl
            });
            const audio = this.$store.state.audioRef;
            audio.src = item.audioUrl;
        },
    },
};
</script>
<style lang="less" scope>
.dialog-content {
    display: flex;
    justify-content: center;

    .small {
        background: #41b035;
        padding: 50px;
    }
}
.chat-play-img {
    margin-right: 30px;
    padding-top: 5px;
}
.dialog-header {
    height: 50px;
    display: flex;
    justify-content: center;
}
.option {
    display: flex;
    justify-content: space-between;

    .name:hover {
        color: #31c27c;
        cursor: pointer;
    }
}
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 20px;
    justify-content: center;
}
.myImg {
    width: 60px;
    border-radius: 10%;
    height: 60px;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.5s;
}
.myImg:hover {
    transform: scale(1.1);
}
.item {
    padding: 5px 50px;
}
/* .light:hover{
    background-image: url('../assets/img/play.png');
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
} */
.light {
    padding: 10px 0px;
    background: #efefef;
    border-radius: 10px;
    display: flex;
}
.header {
    font-size: 14px;
    padding-left: 50px;
    padding-top: 50px;
    color: #757373;
}
</style>