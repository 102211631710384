<template>
    <div class="login-box-container">
        <div class="login-box">
            <div class="form-title">
                <div class="phone-login selected loginbtn">短信登录/注册</div>
            </div>
            <el-form
                ref="loginForm"
                class="sms-login"
                :model="subForm"
                autocomplete="off"
            >
                <el-form-item prop="phone" :rules="phoneRules()">
                    <div class="phone-number form-line">
                        <el-select
                            class="area-selector"
                            v-model="subForm.nationCode"
                        >
                            <el-option
                                v-for="item in nationCodeList"
                                :key="item.value"
                                :value="item.value"
                                :label="'+' + item.value"
                                >{{ item.name + ' +' + item.value }}</el-option
                            >
                        </el-select>
                        <el-input
                            type="text"
                            v-model.trim="subForm.phone"
                            class="input"
                            placeholder="请输入手机号"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item prop="message" :rules="messageRules()">
                    <div class="verification-code form-line">
                        <el-input
                            type="text"
                            class="input"
                            v-model.trim="subForm.message"
                            placeholder="输入手机验证码"
                        ></el-input>
                        <el-button
                            type="text"
                            :disabled="!canISend"
                            @click="handleGetSMSCode"
                            >{{ sendText() }}</el-button
                        >
                    </div>
                </el-form-item>
                <div
                    :class="['commit-button', loading ? 'loading-btn' : '']"
                    @click="handleSubmit"
                >
                    同意并登录
                </div>
                <div class="cancel-button" @click="donotAgree">暂不同意</div>
            </el-form>
        </div>
    </div>
</template>
<script >
import { getUserInfo, login, sendSms } from '../../service/UserService.js';
import { ref } from 'vue';
export default {
    name: 'login',
    components: {
    },
    setup() {
        let loginForm = ref(null);
        return {
            loginForm
        };
    },
    data() {
        return {
            subForm: {
                nationCode: '86',
                message: '',
                language: 'cn',
                phone: '',
                module: 'LOGIN'
            },
            canISend: true,
            timmer: null,
            loading: false,
            time: 60, sendCount: 0, nationCodeList: [
                { name: '中国大陆', value: '86' }
            ]
        };
    },
    methods: {
        sendText() {
            if (this.canISend) {
                if (this.sendCount > 0) {
                    return '重新发送';
                } else {
                    return '获取验证码';
                }
            } else {
                return `${this.time}秒后重新发送`;
            }
        },
        phoneRules() {
            const phoneValidator = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入手机号码'));
                } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                    callback(new Error('请输入正确的手机号码'));
                } else {
                    callback();
                }
            };
            return [{ validator: phoneValidator, trigger: 'blur' }];
        },
        /**
         * 验证码校验规则
         */
        messageRules() {
            const messageValidator = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入验证码'));
                } else if (value.length !== 6) {
                    callback(new Error('请输入正确的验证码'));
                } else {
                    callback();
                }
            };
            return [{ validator: messageValidator, trigger: 'blur' }];
        },

        /**
         * 同意并登录
         */
        handleSubmit() {
            if (this.loading) {
                return false;
            }
            this.loginForm.validate((validate) => {
                if (validate) {
                    this.loading = true;
                    login({ ...this.subForm }).then((res) => {
                        if (res.code === 200) {
                            getUserInfo().then((res) => {
                                if (res.code === 200) {
                                    this.$store.commit('user/SET_USER_INFO', res.data);
                                    if (this.$route.query.redirect) {
                                        const data = { ...this.$route.query };
                                        delete data.redirect;
                                        this.$router.push({
                                            path: this.$route.query.redirect,
                                            query: { ...data }
                                        });
                                    } else {
                                        this.$router.push('/');
                                    }
                                } else {
                                    this.$message.warning(res.message);
                                    this.loading = false;
                                }
                            });
                        } else {
                            this.$message.warning(res.message);
                            this.loading = false;
                        }
                    });
                }
            });
        }
        ,
        /**
         * 不同意
         */
        donotAgree() {
            //
        },

        /**
         * 获取手机验证码
         */
        handleGetSMSCode() {
            this.loginForm.validateField('phone', (validate) => {
                // 验证成功
                if (validate === '') {
                    this.canISend = false;
                    this.time = 60;
                    this.sendCount++;
                    sendSms({ ...this.subForm }).then((res) => {
                        if (res.code === 200) {
                            this.startTimmer();
                        } else {
                            this.startTimmer();
                            this.$message.warning(res.message);
                        }
                    });
                }
            });
        },
        /**
   * 开始倒计时
   */
        startTimmer() {
            if (this.timmer) {
                window.clearInterval(this.timmer);
                this.timmer = null;
            }
            this.timmer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                } else {
                    window.clearInterval(this.timmer);
                    this.timmer = null;
                    this.canISend = true;
                }
            }, 1000);
        }
    },
};
</script>
<style lang='less' scoped>
.login-box-container {
    background-image: url('https://fuugc.allhistory.com/online/img/check_me/login-bg.0256.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        padding: 0;
        margin: 0;
    }
    .login-box {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        padding: 48px;
        background: #fff;
        border-radius: 8px;
        ::v-deep .el-input__inner {
            border: none;
        }
        .el-form-item {
            margin-bottom: 0;
            ::v-deep .el-form-item__error {
                width: 100%;
                text-align: right;
            }
        }
        .form-title {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            margin-bottom: 30px;
            text-align: center;
            font-size: 22px;
            color: #333;
            .phone-login {
                position: relative;
                box-sizing: border-box;
                height: 34px;
                line-height: 22px;
                cursor: pointer;
                padding: 5px;
                border-bottom: 0;
                font-size: 16px;
                color: #9e9e9e;
                transition: all 0.4s;
                color: #333;
                font-size: 18px;
                &::after {
                    width: 100%;
                    content: '';
                    position: absolute;
                    display: block;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 0;
                    background-color: #ff7963;
                    transition: all 0.4s;
                }
            }
        }
        .sms-login {
            .form-line {
                padding: 10px 0 0;
                font-size: 14px;
                border-bottom: 1px solid #dcdcdc;
            }
            .phone-number {
                position: relative;
                z-index: 2;
                display: flex;
                .area-selector {
                    border: none;
                    width: 100px;
                }
                .input {
                }
            }
            .verification-code {
                margin-top: 10px;
                position: relative;
                z-index: 1;
                display: flex;
            }
            .no-code-prompt {
                margin-top: 8px;
                font-size: 12px;
                text-align: right;
                line-height: 17px;
            }
            .agreement-prompt {
                text-align: justify;
                margin-top: 8px;
                font-size: 12px;
                line-height: 17px;
                color: #999;
                position: relative;
            }
            .commit-button {
                cursor: pointer;
                margin-top: 40px;
                height: 42px;
                line-height: 42px;
                border-radius: 21px;
                background-color: #ff7963;
                color: #fff;
                font-size: 14px;
                text-align: center;
                transition: all ease 0.25s;
            }
            .loading-btn {
                background-color: #ff7963;
                opacity: 0.5;
                cursor: not-allowed;
            }
            .cancel-button {
                height: 40px;
                line-height: 40px;
                color: #999;
                text-align: center;
                cursor: pointer;
            }
        }
        .is-error {
            .form-line {
                border-bottom: 1px solid #f56c6c;
            }
        }
    }
}
</style>