export const indexs = [
    'weixin://dl/business/?t=dG57iKBZwbs',
    'weixin://dl/business/?t=HWJA8dnjrJt',
    'weixin://dl/business/?t=jldBxOjP6Pc',
    'weixin://dl/business/?t=tiPzOfFsPis',
    'weixin://dl/business/?t=lESiHeGiWTv',
    'weixin://dl/business/?t=Su7hvn1GXRp',
    'weixin://dl/business/?t=T88wGGSJcwj',
    'weixin://dl/business/?t=pQe4kG0PRYr',
    'weixin://dl/business/?t=WJ6i10YMcYq',
    'weixin://dl/business/?t=GF0wdWMqk2d',
    'weixin://dl/business/?t=a7xaDQmZtkc',
    'weixin://dl/business/?t=mRVSDixpq0q',
    'weixin://dl/business/?t=dAGepG6QXUu',
    'weixin://dl/business/?t=PpJbWedgdBr',
    'weixin://dl/business/?t=Y1dhB2Khmdd',
    'weixin://dl/business/?t=JUN54dRYK6b',
    'weixin://dl/business/?t=aKNWsNitVVf',
    'weixin://dl/business/?t=mfpVdNUPGGj',
    'weixin://dl/business/?t=9FYTkoM8O4m',
    'weixin://dl/business/?t=lzmEBg9cERl',
    'weixin://dl/business/?t=KED6bZ3dSZd',
    'weixin://dl/business/?t=cLfXwi3S2wu',
    'weixin://dl/business/?t=mJt0Zlt0UIl',
    'weixin://dl/business/?t=7oshilXzbWj',
    'weixin://dl/business/?t=kr3hhVwPJdc',
    'weixin://dl/business/?t=MVRHp7irA8b',
    'weixin://dl/business/?t=4em6UDmW08o',
    'weixin://dl/business/?t=Y7WSmrxqfhr',
    'weixin://dl/business/?t=j4FUqjzfGIp',
    'weixin://dl/business/?t=9sbWTPhpqDj',
    'weixin://dl/business/?t=yHPGngNMYBh',
    'weixin://dl/business/?t=yx9tQ4voBPd',
    'weixin://dl/business/?t=xWdgKxVwbKs',
    'weixin://dl/business/?t=7FCWNOEKKhq',
    'weixin://dl/business/?t=G2VTvLv2tnv',
    'weixin://dl/business/?t=yAkLL95DBej',
    'weixin://dl/business/?t=hptSq0obi1f',
    'weixin://dl/business/?t=G5kYXoWnGTe',
    'weixin://dl/business/?t=LO2suZu9w6c',
    'weixin://dl/business/?t=modnLLdeOSg',
    'weixin://dl/business/?t=HCcsDCJh6Ka'
]