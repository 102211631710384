/*
 * @Author: liupengwu
 * @Date: 2021-02-22 17:16:30
 * @Description: file content
 */
import { getJson, postJson } from '../utils/futureAjaxUtil.js';

//登录
export function login(params) {
    return postJson(`/api/future/login/v1/n/sms/check`, params);
}

//发送短信验证码接口
export function sendSms(params) {
    return postJson(`/api/future/login/v1/n/sms/send`, params);
}

//获取用户信息接口
export function getUserInfo() {
    return getJson(`/api/future/cms/user/info`);
}
//获取用户信息接口
export function logout() {
    return getJson(`/api/future/login/v1/y/logout`);
}

