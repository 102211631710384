/*
 * @Author: liupengwu
 * @Date: 2021-02-05 12:46:42
 * @Description: file content
 */
import { createRouter, createWebHashHistory } from 'vue-router';
import layout from '../layout.vue';
import Home from '../views/Home.vue';
import MusicList from '../views/MusicList.vue';
import Dispute from '../views/Dispute.vue';
import Examine from '../views/Examine.vue';
import News from '../views/News.vue';
import UserAgreement from '../views/agreement/UserAgreement.vue';
import TechnologyAgreement from '../views/agreement/TechnologyAgreement.vue';
import login from '../views/signin/index.vue';
import miniprogram from '../views/miniprogram/index.vue';
import CityApp from '../views/cityapp';
import License from '../views/license'

const routes = [
  {
    path: '/login',
    component: login,
    hidden: true,
    meta: {
      title: '全未来'
    },
  },
  {
    path: '/sp/:id?',
    component: miniprogram,
    hidden: true,
    meta: {
      title: '完美活动'
    },
  },
  {
    path: '/cityapp',
    component: CityApp,
    meta: {
      title: '完美活动'
    },
  },
  {
    path: '/license',
    component: License,
    meta: {
      title: '网络文化经营许可证'
    },
  },
  {
    path: '/',
    name: 'App',
    redirect: '/home',
    component: layout,
    meta: {
      title: '全未来'
    },
    children: [
      {
        //音乐列表
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        //音乐包
        path: '/musicList',
        name: 'musicList',
        component: MusicList
      },
      {
        //纠纷处理
        path: '/dispute',
        name: 'dispute',
        component: Dispute
      },
      {
        //自审制度
        path: '/examine',
        name: 'examine',
        component: Examine
      },
      {
        //自审制度
        path: '/news',
        name: 'news',
        component: News
      },
      {
        //用户权益保障制度
        path: '/userAgreement',
        name: 'userAgreement',
        component: UserAgreement
      },
      {
        //技术安全保障制度
        path: '/technologyAgreement',
        name: 'technologyAgreement',
        component: TechnologyAgreement
      }
    ]
  },
  // {
  //   path: '/',
  //   name: 'App',
  //   redirect: '/home',
  //   component: layout,
  // }

];


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 一定不要忘记调用 next()
  next();
});

export default router;
