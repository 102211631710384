<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 12:46:42
 * @Description: file content
-->
<template>
    <router-view />
    <div class="g-btmbar" v-show="$store.state.audioParams.show">
        <div class="m-playbar">
            <div class="btn-audio clear">
                <img
                    :src="$store.state.audioParams.bgImg"
                    alt=""
                    class="lf"
                    style="width: 5.2rem"
                />
                <div class="lf">
                    <p
                        style="
                            font-size: 1.2rem;
                            color: #333;
                            margin: 0.7rem 0 0.3rem 0.8rem;
                        "
                    >
                        {{ $store.state.audioParams.songName }}
                    </p>
                    <p
                        style="
                            font-size: 1rem;
                            color: #888;
                            margin: 0 0 0 0.8rem;
                        "
                    >
                        {{ $store.state.audioParams.songName }}
                    </p>
                </div>
                <audio ref="myRef" src="" controls autoplay></audio>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { getUserInfo } from './service/UserService.js';
export default {
    created() {
        getUserInfo().then((res) => {
            if (res.code === 200) {
                this.$store.commit('user/SET_USER_INFO', res.data);
            }
        });
    },
    setup() {
        let myRef = ref(null);
        const store = useStore();
        onMounted(() => {
            if (myRef.value) {
                myRef.value.playbackRate = 5;
                store.commit('setAudioRef', {
                    audioRef: myRef.value
                });
            }
            //如果input元素换成div元素
            //获取div的文本内容
        });
        return {
            myRef
        };
    },
    

};

</script>
<style lang="less" scope>
.g-btmbar {
    height: 100px;
    background-color: royalblue;
    position: fixed;
    _position: absolute;
    zoom: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    z-index: 1002;

    .m-playbar {
        position: absolute;
        zoom: 1;
        top: -78px;
        left: 0;
        width: 100%;
        height: 78px;
        margin: 0 auto;
    }
}
</style>
