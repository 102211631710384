<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 12:46:42
 * @Description: file content
-->
<template>
  <div class="home">
    <el-carousel height="600px">
      <el-carousel-item v-for="item in $store.state.carouselData" :key="item" style="color:red">
        <el-image class="lb-pic" fit="contain" style @click="palySong(item)" :src="item.imgUrl"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="musicSt">
      <div class="musicFont" @click="hanldCilck">音乐试听</div>
    </div>
    <div v-for="(song, index) in $store.state.allSongList" :key="song.id + index">
      <div class="middle" v-if="index < 8">
        <div class="lg-big">
          <div class="lg-text">
            <img v-if="index < 3" :src="getImgUrl(index)" />
            {{ song.name }}
          </div>
          <div class="ls-st" @click="palySong(song)">点击试听</div>
        </div>
        <div>
          <img class="right-bg" @click="palySong(song)" :src="song.imgUrl" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIndexFour, getAllSong } from '../service/FutureService.js'
// import store from '../store/index';
export default {
  name: 'Home',
  components: {},
  mounted() {
    getIndexFour().then((resp) => {
      if (resp.code === 200) {
        this.$store.commit('setcarouselData', resp.data || [])
      }
    })
    getAllSong().then((resp) => {
      if (resp.code === 200) {
        this.$store.commit('setAllSongList', resp.data || [])
      }
    })
  },
  methods: {
    getImgUrl(index) {
      return require(`../assets/img/${index + 1}.png`)
    },
    //轮播声音背景点击事件
    palySong(item) {
      this.$store.commit('changeAudio', {
        show: true,
        songName: item.name,
        bgImg: item.imgUrl,
      })
      const audio = this.$store.state.audioRef
      audio.src = item.audioUrl
    },
    hanldCilck() {
      this.$store.commit('changeAudio', {
        show: true,
      })
    },
  },
}
</script>

<style  lang="less" scope>
.carousel__item {
  text-align: center;
}
.el-carousel__container {
  .el-carousel__item {
    text-align: center;
  }
}
.white-div {
  width: 15px;
}
.right-pic {
  width: 300px;
}
.right-pic:hover {
  cursor: pointer;
}
.right-bg {
  width: 250px;
  cursor: pointer;
  transition: all 0.5s;
}
.right-bg:hover {
  transform: scale(1.1);
}
.lb-pic {
  width: 100%;
  height: 100%;
}
.lb-pic:hover {
  cursor: pointer;
}
.ls-st {
  padding: 2px 5px;
  border: 1px #969191 solid;
  border-radius: 8px;
  background: #dcdcdcc2;
  width: 60px;
  text-align: center;
  min-width: 60px;
}
.ls-st:hover {
  cursor: pointer;
  background: #d2d2d2;
}
.lg-big {
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.middle {
  display: flex;
  justify-content: center;
  padding: 20px 200px;

  .lg-text {
    font-size: 16px;
    height: 50px;
  }

  .num-one {
    font-size: 16px;
    color: red;
  }
}
.musicSt {
  height: 140px;
  background-color: #e3eef573;
  display: flex;
  padding: 0 200px;
  justify-content: center;
}
.musicFont {
  color: #b3b2b2;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.btn-audio {
  display: flex;
  border: 1px solid #ebebeb;
  background-color: #fdfdfd;
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
  justify-content: center;
  padding: 5px 0;
}
.mp3Box {
  width: 2.8rem;
  height: 2.8rem;
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  /* background:url("image/close.png") no-repeat center bottom; */
  background-size: cover;
  z-index: 100;
}
.lf {
  padding-right: 10px;
}
</style>
