<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 14:24:01
 * @Description: file content
-->
<template>
  <div class="jfcl">
    <h1 class="title">纠纷处理</h1>
    <p
      class="usually"
    >我公司按照文化部的相关要求，在网站的醒目位置标明纠纷处理方式。用户在注册、登陆本平台账号前请务必仔细阅读本《纠纷处理方式》（未成年人应当在其法定监护人陪同下阅读）中的各个条款，以确保您在享受本平台服务的同时维护自身合法权益：</p>
    <p class="usually weight">用户之间纠纷处理方式</p>
    <p
      class="usually"
    >1、 北京完美全未来信息技术有限公司仅作为本平台的运营商，如若本平台用户之间发生纠纷且无法协商一致的，可向国家相关行政机关或司法机关寻求解决包括但不限于向公安机关报案、申请仲裁或向人民法院提起诉讼等。北京完美全未来信息技术有限公司将会为实名注册用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或采取必要措施。</p>
    <p
      class="usually"
    >2、本平台用户之间发生纠纷的，也可向北京完美全未来信息技术有限公司的客服人员投诉并举证。用户需提供与其账号注册信息一致的个人有效身份证件、必要的国家行政或司法机关文件和其他北京完美全未来信息技术有限公司要求提供的相关证据。经北京完美全未来信息技术有限公司审核确认后，可以给予必要的协助和支持。</p>

    <p class="usually weight">用户与北京完美全未来信息技术有限公司之间纠纷处理方式</p>
    <p
      class="usually"
    >1. 如本平台用户对北京完美全未来信息技术有限公司的服务有任何异议的，可以向北京完美全未来信息技术有限公司的客服人员投诉并举证。查证属实的，北京完美全未来信息技术有限公司将立即更正并按照现行法律规定给予用户必要的补偿。</p>
    <p
      class="usually"
    >2. 如本平台用户对北京完美全未来信息技术有限公司的服务有任何异议的，也可以向双方约定（具体参见《北京完美全未来信息技术有限公司网站使用协议》）的北京完美全未来信息技术有限公司所在地人民法院提起诉讼。2. 如本平台用户对北京完美全未来信息技术有限公司的服务有任何异议的，也可以向双方约定（具体参见《北京完美全未来信息技术有限公司网站使用协议》）的北京完美全未来信息技术有限公司所在地人民法院提起诉讼。</p>
    <p class="usually">公司全称：北京完美全未来信息技术有限公司</p>
    <p class="usually">公司地址：北京市朝阳区望京东路1号4层401内A406</p>
    <p class="usually">负责人：王磊</p>
    <p class="usually">联系电话：18601083080</p>
    <p class="usually">固定电话：010-57806389</p>
  </div>
</template>
<script>
export default {
  name: 'dispute',
}
</script>
<style lang="less"  scoped >
.jfcl {
  padding: 0 20%;
  padding-bottom: 30px;
  .usually {
    line-height: 24px;
    font-size: 14px;
    /* margin-bottom: 16px; */
    text-indent: 28px;
    margin: 8px 0px !important;
  }
  .title {
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 32px;
    text-align: center;
  }
  .weight {
    font-weight: bold;
  }
}
</style>