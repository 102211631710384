/*
 * @Author: liupengwu
 * @Date: 2021-02-22 16:40:18
 * @Description: file content
 */
export const nodeEnv = process.env.NODE_ENV;
// interface Env {
//   baseUrl: string;
// }
// interface EnvList {
//   development: Env;
//   test: Env;
//   preview: Env;
//   production: Env;
// }
export const envList = {
  development: {
    baseUrl: 'http://10.4.40.54',
  },
  test: {
    baseUrl: 'http://10.4.40.54'
  },
  preview: {
    baseUrl: 'https://evtpre.tomorrow365.com'
  },
  production: {
    baseUrl: 'https://evt.tomorrow365.com'
  }
};
export const baseUrl = envList[nodeEnv].baseUrl;


