import { Object } from 'core-js';
import { createStore } from 'vuex';
import { user } from '../store/user/index.js';
import createVuexAlong from 'vuex-along';

export default createStore({
  //状态数据
  state: {
    audioParams: {
      show: false,
      songName: '',
      bgImg: ''
    },
    audioRef: null,
    allSongList: [],
    carouselData: []

  },
  //更改数据的方法
  mutations: {
    //改变控制audio的属性
    changeAudio(state, payload) {
      Object.assign(state.audioParams, payload);
    },
    //存放整个audio 的ref
    setAudioRef(state, payload) {
      state.audioRef = payload.audioRef;
    },
    //set音乐列表
    setAllSongList(state, payload) {
      state.allSongList = payload;
    },
    //set轮播图数据
    setcarouselData(state, payload) {
      state.carouselData = payload;
    },
    //根据支付成功后的item刷新两个缓存allSongList，carouselData
    changeTwoArrByItem(state, payload) {
      if (state.carouselData?.length) {
        state.carouselData = state.carouselData.map((item) => {
          if (payload.id === item.id) {
            return payload;
          } else {
            return item;
          }
        });
      }
      if (state.allSongList?.length) {
        state.allSongList = state.allSongList.map((item_) => {
          if (payload.id === item_.id) {
            return payload;
          } else {
            return item_;
          }
        });
      }
    },
    //退出制空user
    clearUser(state) {
      state.user = {};
    },
  },
  actions: {
  },
  modules: {
    user
  },
  plugins: [createVuexAlong()]
});
