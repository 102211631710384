<template>
  <div class="public-web-container full">
    <p>正在打开 “完美活动”...</p>
    <a
      ref="a"
      :href="url"
      class="weui-btn weui-btn_primary"
    >打开微信小程序</a>
  </div>
</template>
<script>
import { onMounted, ref, nextTick } from 'vue'
import { useRoute } from 'vue-router';
import { indexs } from './config.ts'
export default {
  setup() {
    const route = useRoute()
    const id = route.params.id
    let url = ref('0')
    const a = ref(null)
    url.value = indexs?.[id] || `weixin://dl/business/?t=${id}`
    onMounted(() => {
      nextTick(() => {
        a.value.click()
      })
    })
    return {
      url,
      a,
    }
  }
}
</script>
<style lang="less" scoped>
.public-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    position: absolute;
    top: 40%;
  }
  a {
    position: absolute;
    bottom: 40%;
  }
  .weui-btn_primary {
    background-color: #07c160;
  }
  .weui-btn {
    display: block;
    width: 184px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 24px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    line-height: 1.41176471;
    border-radius: 4px;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}
.full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>