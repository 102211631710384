<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 14:24:01
 * @Description: file content
-->
<template>
  <div class="zszd">
    <h1 class="title">音乐内容自审制度</h1>
    <p
      class="usually"
    >为规范我公司网文文化产品及服务内容的企业自审工作，明确我公司开展相关工作的职责、流程、标准以及责任追究办法。根据《网络文化经营单位内容自审管理办法》的相关要求特制订本制度。</p>
    <p class="usually light">一、机构设置</p>
    <p
      class="usually"
    >自即日起成立独立于各个业务部门的内容审查委员会，委员包括技术负责人或主产品以及运营负责人。由公司总经理负责委员会的召集和日常运作事宜。委员会的主要任务是在产品开发过程中规范相关音乐内容；在版本验收环节对可能涉及违反法律法规的内容进行自查自纠；在音乐运营过程中随时检查各种营销素材、音乐活动及其他内容是否有不适当的内容出现。并且一旦发现有违规情况的发生，委员会负责对相关责任人和部门进行查处惩戒。</p>
    <p class="usually light">二、日常运作</p>
    <p
      class="usually"
    >委员会每周召集一次全体会议，就公司产品开发过程中的各个环节当中的内容来源、表现方式进行集中审查和讨论，每周在公司内公布违规内容自查结果，如没有违规内容则不公布。委员会委员有义务将最近工作过程中所开发的内容对其他委员进行公开展示，并接受质询。运营负责人将针对音乐测试过程中出现的所有可能涉及违规的内容进行通告，并要求各部门限期整改。</p>
    <p class="usually light">三、内部培训</p>
    <p class="usually">为确保及时准确的了解国家最新有关政策法规，由委员会召集定期对内部人员进行有关法规的讲解和培训，并进行考试。覆盖对象主要是产品、运营及各部门负责人。</p>
    <p class="usually">培训不定期举行，主要是针对最新出台的法规政策，以及新近升职上任的管理人员所进行。</p>
    <p class="usually light">四、自审内容准则</p>
    <p class="usually">网络音乐不得含有以下内容：</p>
    <p class="usually">（一）违反宪法确定的基本原则的；</p>
    <p class="usually">（二）危害国家统一、主权和领土完整的；</p>
    <p class="usually">（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；</p>
    <p class="usually">（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；</p>
    <p class="usually">（五）宣扬邪教、迷信的；</p>
    <p class="usually">（六）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p class="usually">（七）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；</p>
    <p class="usually">（八）侮辱、诽谤他人，侵害他人合法权益的；</p>
    <p class="usually">（九）违背社会公德的；</p>
    <p class="usually">（十）有法律、行政法规和国家规定禁止的其他内容的。</p>
    <p class="usually light">五、责任追究</p>
    <p class="usually">明确内部对审核人员、开发人员及运营人员的内容审核责任追究规定。</p>
    <p class="usually">对审核人员未能在审核过程中发现问题的，予以口头警告； 对审核人员发现问题，但开发人员拒不修改的，予以书面警告；</p>
    <p class="usually">对审核人员发现问题，但开发人员修改，审核通过后自行予以回复的，予以扣除当月奖金；</p>
    <p
      class="usually"
    >对运营人员在运营过程中未通知审核人员，造成不良后果， 而审核人员也未及时发现的，予以扣除当月奖金，累积满三次运营人员予以调离岗位，审核人员予以取消审核资格并调离岗位；其他造成不良后果情形的，予以书面警告。</p>
    <p class="usually light">六、审核流程</p>
    <p
      class="usually"
    >我公司按照《网络文化经营单位内容自审管理办法》规定， 建立自审管理制度，对网络文化产品及服务内容进行事前审核， 网络文化产品及服务的内容审核由 2 名以上审核人员实施，审核人员填写审核意见并签字后，报本单位内容管理负责人复核签字。我公司通过技术手段对网站（平台）运行的产品及服务的内容进行实时监管，发现违规内容的要立即停止提供，保存有关记录，内容审核记录的保存期不少于 2 年，重大问题向所在地省级文化行政部门报告。</p>
    <p class="usually light">七、具体操作：</p>
    <p class="usually">1、设立自审信息安全小组</p>
    <p
      class="usually"
    >2、信息安全小组负责对公司所有网络资源进行实时监控， 做到及时发现、即时处理的原则办理，并对处理结果备案，对重大有害信息事件，应在第一时间上报主管领导及相关部门。</p>
    <p
      class="usually"
    >3、信息安全小组负责界定、监控、受理有害信息事件，要做到即接快办；夜间、节假日值班期间接到、发现的，应于次日或节假日后的第一个工作日办理，对需紧急办理的重大信息安全事件可先处理后登记（如遇紧急情况，可直接关闭服务器等设备， 暂停网络运行）。</p>
    <p class="usually">自 审 人 员：</p>
    <p class="usually">姓 名 : 阳雄</p>
    <p class="usually">联 系 方 式 : 13691051366 邮箱: yangxiong602766@pwrd.com</p>
    <p class="usually">自审人员职责:内容审核初审。负责对公司所有网络资源进行实时监控，并对处理结果备案，对重大有害信息事件，可上报主管领导。</p>
    <p
      class="usually"
    >自审人员职责:内容审核复审。对于内容审核初审人员提交的审核信息及相关问题进行复核并给出反馈意见，同时负责在每阶段项目验收环节对可能涉及违反法律法规的内容进行自查，一旦发现有违规情况的发生，向上级主管领导汇报。</p>
    <p class="usually">自审人员负责人：</p>
    <p class="usually">姓名: 朱玥</p>
    <p class="usually">联系方式: 18518754628，邮箱:zhuyue@pwrd.com</p>
    <p
      class="usually"
    >自审人员职责:内容审核终审。负责对于内容初审、复审人员提交的审核结果进行最终审核并对审核结果负责，同时负责与开发人员在内容生产、内容宣传、内容上线全流程的跟盯并进行沟通、协调。</p>
    <p
      class="usually"
    >4、负责查办的相关人员接到交办的事件后，应及时安排办理，要求在最短时限内处理完毕，如遇特殊情况不能按时处理完毕的，应报主管领导说明情况，可适当延长处理时间，处理结果应及时反馈给信息安全小组组长。在处理有害信息事件时，应按照处理流程，及时填写相应表单，并随处理结果报告一并存档。</p>
    <p class="usually">5、处理人员应对重大有害信息时间的举报人、发现人要求保密者做到保密，有关重大的有害信息事件及处理过程不得泄密。</p>
  </div>
</template>
<script>
export default {
  name: 'examine',
}
</script>
<style lang="less"  scoped >
.zszd {
  padding: 0 20%;
  padding-bottom: 30px;
  .usually {
    line-height: 24px;
    font-size: 14px;
    /* margin-bottom: 16px; */
    text-indent: 28px;
    margin: 8px 0px !important;
  }
  .title {
    height: 36px;
    line-height: 36px;
    font-size: 24px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 32px;
    text-align: center;
  }
  .light {
    font-weight: bold;
  }
}
</style>