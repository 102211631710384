/*
 * @Description:
 * @Author: YUYING
 * @Date: 2019-11-25 17:53:52
 * @LastEditTime: 2021-02-25 16:11:01
 */

import axios from '@ah/dls-axios';
import { baseUrl } from '../config/index.js';
// import Cookies from 'js-cookie'
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.timeout = 10000;
// axios.defaults.baseURL = baseUrl;

// const TokenKey = 'bearer'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token: string) {
//   return Cookies.set(TokenKey, token)
// }





//store requesting list
window.REQUESTURL = [];
// window.REQUESTURL = {}

// var domainName = '__ALLHISTORY_HOSTNAME__';

// var domainName = 'https://evatlas.com:8443';
// var domainName = 'http://webdev.evatlas.com:8443';

//http post
export const postJson = (url, params = '') => {
  return myAxios({ url, data: params, method: 'POST' });
};

//http get
export const getJson = (url, params = '') => {
  return myAxios({ url, data: params, method: 'GET' });
  // return sendRequest(url, 'get', params, config);
};
//http delete
export const deleteJson = (url, params = '') => {
  return myAxios({ url, data: params, method: 'DELETE' });
  // return sendRequest(url, 'delete', params, config);
};




/**
 * 请求方法的封装
 */
export const myAxios = (param) => {
  const method = String(param.method || 'GET').toUpperCase();
  // axios参数
  const config = {
    method: param.method,
    url: baseUrl + param.url,
    headers: Object.assign({
      'X-APP-APPID': 3,
      // 'App-Client-Id': 10009,
    }, param.headers),
    cancelToken: param.cancelToken,
  };

  if (method === 'GET') {
    Object.assign(config, { params: param.data || {} });
  } else {
    Object.assign(config, { data: param.data || {} });
  }

  return new Promise((resolve, reject) => {
    axios({
      ...config,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};