/*
 * @Author: liupengwu
 * @Date: 2021-02-22 19:40:17
 * @Description: file content
 */

export const user = {
  namespaced: true,
  state: {
    userInfo: {}
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    }
  },
  mutations: {
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    }
  },
  actions: {

  }
};