<template>
  <div class="cityapp">
    <div class="pc" v-if="agent == 'pc'">
      <div class="background"></div>
      <div class="phone"></div>
      <div class="slogan"></div>
      <div class="logo"></div>
      <div class="download">
        <a target="_blank" :href="iosUrl">
          <div class="btn">
            <i class="icon-iphone"></i>
            <span>iOS版下载</span>
          </div>
          <div class="a-shadow"></div>
        </a>
        <a target="_blank" :href="androidUrl">
          <div class="btn">
            <i class="icon-android"></i>
            <span>安卓版下载</span>
          </div>
          <div class="a-shadow"></div>
        </a>
      </div>
    </div>
    <div class="mobile" v-else-if="agent == 'mobile'">
      <div class="background"></div>
      <div class="logo"></div>
      <div class="phone"></div>
      <div class="mask"></div>
      <div class="slogan"></div>
      <div @click='goDownload' v-if="isWeixin" class="download">
        <a>立即下载</a>
      </div>
      <div v-else class="download">
        <a :href="downloadUrl">立即下载</a>
      </div>
      <div class="download-shadow"></div>
    </div>
  </div>
</template>
<script>
import isMobile from 'ismobilejs';
export default {
  name: 'cityapp',
  data(){
    return {
      agent: null,
      isWeixin: false,
      downloadUrl: 'https://www.tomorrow365.com',
      iosUrl: 'https://apps.apple.com/cn/app/id1555414234',
      androidUrl: 'https://oss.allhistory.com/android-app/2021-06-25/60d5a11cf8ba0836eed8902f.apk'
    }
  },
  beforeCreate() {
    document.title = '完美活动'
  },
  created() {
    if(isMobile(window.navigator).apple.device){
      this.downloadUrl = this.iosUrl;
      this.agent = 'mobile'
    }
    else if (isMobile(window.navigator).android.device) {  //判断Android
      this.downloadUrl = this.androidUrl;
      this.agent = 'mobile'
    } else { //pc
        this.downloadUrl = 'https://www.tomorrow365.com';
        this.agent = 'pc'
    }

    if (/MicroMessenger/i.test(navigator.userAgent) || (window.location.search && window.location.search.indexOf('w=1') >= 0)) {
      if (isMobile(window.navigator).android.device) {  //判断Android
        this.isWeixin = true;
      }
    }
  },
  methods: {
    goDownload(e) {
      e.preventDefault();
      alert('点击右上角按钮，然后弹出的菜单中，点击在浏览器中打开，即可安装');
    }
  }
}
</script>
<style lang="less" scoped>
  .cityapp {
    width: 100vw;
    height: 100vh;
    .pc {
      width: 100%;
      height: 100%;
      position: relative;
      .background {
        background-image: url('../../assets/img/cityapp/background.png');
        background-repeat: no-repeat;
        height: 32vw;
        background-size: 100% 32vw;
      }
      .phone {
        position: absolute;
        background-image: url('../../assets/img/cityapp/phone.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 33vw;
        height: 69vw;
        top: 5vw;
        right: 9vw;
      }
      .slogan {
        position: absolute;
        background-image: url('../../assets/img/cityapp/slogan.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 49vw;
        height: 16vw;
        top: 28vw;
        left: 6vw;
      }
      .logo {
        position: absolute;
        background-image: url('../../assets/img/cityapp/logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 26vw;
        height: 7vw;
        top: 13vw;
        left: 9vw;
      }
      .download {
        position: absolute;
        top: 48vw;
        left: 8vw;
        display: flex;
        a {
          text-decoration: none;
          position: relative;
          .btn {
            position: relative;
            z-index: 2;
            width: 300px;
            height: 96px;
            line-height: 96px;
            text-align: center;
            background-color: #ff2773;
            border-radius: 12px;
            font-size: 27px;
            color: #fff;
            margin-right: 48px;
            cursor: pointer;
            // box-shadow: 0px 10px 20px 1px rgba(255, 39, 115, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-iphone {

              background-image: url('../../assets/img/icon_iphone.png');
              background-repeat: no-repeat;
              background-size: 36px 36px;
              margin-right: 12px;
              width: 36px;
              height: 36px;
            }
            .icon-android {
              background-image: url('../../assets/img/icon_android.png');
              background-repeat: no-repeat;
              background-size: 36px 36px;
              margin-right: 12px;
              width: 36px;
              height: 36px;
            }
          }
          .a-shadow {
            position: absolute;
            width: 270px;
            height: 72px;
            top: 37px;
            left: 150px;
            background: #ff2773;
            opacity: 0.2;
            filter: blur(7px);
            transform: translateX(-50%);
          }
        }
      }
    }
    .mobile {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      .background {
        background-image: url('../../assets/img/cityapp/background-2.png');
        background-repeat: no-repeat;
        height: 69vw;
        background-size: 100% 69vw;
      }
      .logo {
        position: absolute;
        background-image: url('../../assets/img/cityapp/logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 43vw;
        height: 12vw;
        top: 6vw;
        left: 50%;
        transform: translateX(-50%);
      }
      .phone {
        position: absolute;
        background-image: url('../../assets/img/cityapp/phone.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 77vw;
        height: 162vw;
        top: 24vw;
        left: 50%;
        transform: translateX(-50%);
      }
      .mask {
        position: absolute;
        width: 100vw;
        height: 87vw;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 33.3%);
      }
      .slogan {
        position: absolute;
        background-image: url('../../assets/img/cityapp/slogan-2.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 82vw;
        height: 27vw;
        bottom: 35vw;
        left: 50%;
        transform: translateX(-50%);
      }
      .download {
        z-index: 2;
        position: absolute;
        width: 77vw;
        height: 14vw;
        background: #ff2773;
        border-radius: 2vw;
        font-size: 5vw;
        font-weight: 500;
        color: #fff;
        text-align: center;
        line-height: 14vw;
        bottom: 13vw;
        left: 50%;
        transform: translateX(-50%);
        // box-shadow: 0px 10px 10px 1px rgba(255, 39, 115, 0.2);
        a {
          &:active {
            text-decoration: none;
          }
          &:hover {
            text-decoration: none;
          }
          display: block;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #fff;
          user-select: none;
          box-shadow: none;
        }
      }
      .download-shadow {
        position: absolute;
        width: 75vw;
        height: 7vw;
        background: #ff2773;
        opacity: 0.2;
        filter: blur(7px);
        bottom: 10vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
</style>