<!--
 * @Author: liupengwu
 * @Date: 2021-02-05 14:24:01
 * @Description: file content
-->
<template>
  <div class="news">
    <div class="content-body-info">
      <div class="content-body">
        <div class="layout-item">
          <div class="content-item" @click="viewDilog">
            <div class="img-content">
              <img class="news_img" fit="contain" src="../assets/img/new_1.png" alt />
            </div>
            <div class="font-text">清华大学集成电路学院正式成立</div>
            <div class="three-ds">
              <div class="view-detail">查看详细资讯></div>
              <div class="rmb">￥1.00</div>
            </div>
          </div>
        </div>
        <div class="layout-item">
          <div class="content-item" @click="viewDilog">
            <div class="img-content">
              <img class="news_img" fit="contain" src="../assets/img/new_2.png" alt />
            </div>
            <div class="font-text">观察｜上海车展：新新造车扎堆，自动驾驶成新赛道</div>
            <div class="three-ds">
              <div class="view-detail">查看详细资讯></div>
              <div class="rmb">￥1.00</div>
            </div>
          </div>
        </div>
        <div class="layout-item">
          <div class="content-item" @click="viewDilog">
            <div class="img-content">
              <img class="news_img" fit="contain" src="../assets/img/new_3.png" alt />
            </div>
            <div class="font-text">重构音频逻辑：做平台，更要做内容</div>
            <div class="three-ds">
              <div class="view-detail">查看详细资讯></div>
              <div class="rmb">￥1.00</div>
            </div>
          </div>
        </div>
        <div class="layout-item">
          <div class="content-item" @click="viewDilog">
            <div class="img-content">
              <img class="news_img" fit="contain" src="../assets/img/new_4.png" alt />
            </div>
            <div class="font-text">互联网行业反垄断趋势</div>
            <div class="three-ds">
              <div class="view-detail">查看详细资讯></div>
              <div class="rmb">￥1.00</div>
            </div>
          </div>
        </div>
        <div class="layout-item">
          <div class="content-item" @click="viewDilog">
            <div class="img-content">
              <img class="news_img" fit="contain" src="../assets/img/new_5.png" alt />
            </div>
            <div class="font-text">美团明年无人配送，大疆要造车，无人机的拐点来了？</div>
            <div class="three-ds">
              <div class="view-detail">查看详细资讯></div>
              <div class="rmb">￥1.00</div>
            </div>
          </div>
        </div>
        <div class="layout-item">
          <div class="content-item" @click="viewDilog">
            <div class="img-content">
              <img class="news_img" fit="contain" src="../assets/img/new_6.png" alt />
            </div>
            <div class="font-text">音乐APP攻防战：QQ、酷狗、酷我、网易云，谁会成为下一个虾米？</div>
            <div class="three-ds">
              <div class="view-detail">查看详细资讯></div>
              <div class="rmb">￥1.00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" width="18%" :before-close="handleClose">
      <el-alert type="info" :closable="false">请使用微信支付扫码</el-alert>
      <img class="news_img" :src="'data:image/bmp;base64,'  + qrCodeImg" fit="contain" alt />
    </el-dialog>
  </div>
</template>
<script>
import { getQRCode } from '../service/FutureService.js'
export default {
  name: 'examine',
  data() {
    return {
      dialogVisible: false,
      qrCodeImg: '',
    }
  },
  methods: {
    viewDilog() {
      this.dialogVisible = true
      getQRCode(102).then((resp) => {
        this.qrCodeImg = resp.data.base64
        this.dialogVisible = true
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
  },
}
</script>
<style lang="less"  scoped >
.news {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #fafafa;
  overflow-y: auto;
  .three-ds {
    display: flex;
    padding: 0px 25px;
    font-size: 16px;
    justify-content: space-between;

    .view-detail {
      font-size: 12px;
      color: rgb(134, 133, 133);
      display: flex;
      align-items: center;
    }
    .rmb {
      font-size: 22px;
      color: #ec660d;
    }
  }
  .font-text {
    padding: 0px 25px;
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 16px;
    font-weight: bold;
    color: #545454;
    line-height: 26px;
    height: 57px;
  }
}
.content-body-info {
  margin: auto;
  width: 1088px;
  overflow: hidden;

  .content-body {
    flex-wrap: wrap;
    background: white;
    margin: 0 auto;
    // height: 1385px;
    position: relative;
    margin: 50px 0;
    display: flex;
  }
}
.layout-item {
  width: 362px;
}
.content-item {
  cursor: pointer;
  padding: 15px 15px;
  .img-content {
    text-align: center;
  }
  // width: 280px;
}
.news_img {
  padding: 15px;
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
}
</style>